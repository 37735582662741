<template>
  <div id="home-advanced-search" class="gray open">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <form v-on:submit.prevent="searchProps">
            <div class="form-group">
              <!-- KEYWORD -->
              <div v-if="!search.searchDevelopments" class="form-control-large">
                <input
                  v-model="search.keyword"
                  type="text"
                  class="form-control"
                  :placeholder="$t('layout.search_form.keyword_input')"
                />
              </div>

              <!-- PROPERTY TYPE -->
              <div class="form-control-large">
                <select
                  name="select-type"
                  class="form-control"
                  id="select-type"
                  v-model="search.type"
                >
                  <option selected value="">
                    {{
                      $t(
                        `layout.search_form.${
                          !search.searchDevelopments
                            ? "property_type_input"
                            : "development_type_input"
                        }`
                      )
                    }}
                  </option>
                  <option
                    v-for="(type, i) in !search.searchDevelopments
                      ? input.types
                      : input.typesDevs"
                    :key="i"
                    :index="i"
                    :value="type.typepropertyid"
                  >
                    {{ type.typepropertyspa }}
                  </option>
                </select>
              </div>

              <!-- STATUS -->
              <div v-if="!search.searchDevelopments" class="form-control-small">
                <select
                  class="form-control"
                  name="select-city"
                  id="select-city"
                  v-model="search.operation"
                >
                  <option selected value="">
                    {{ $t("layout.search_form.operation_input") }}
                  </option>
                  <option value="1">
                    {{ $t("layout.search_form.option_sold") }}
                  </option>
                  <option value="2">
                    {{ $t("layout.search_form.option_rent") }}
                  </option>
                  <option value="3">
                    {{ $t("layout.search_form.option_vacation") }}
                  </option>
                </select>
              </div>

              <!-- PRICE MIN -->
              <div class="form-control-small">
                <label class="label-switch">
                  {{ $t("layout.search_form.min_price_input") }}</label
                >
                <money
                  type="text"
                  class="form-control"
                  step="any"
                  name="minprice"
                  v-model="search.minPrice"
                  v-bind="moneyMin"
                ></money>
              </div>

              <!-- PRICE MAX -->
              <div class="form-control-small">
                <label class="label-switch">
                  {{ $t("layout.search_form.max_price_input") }}</label
                >
                <money
                  type="text"
                  class="form-control"
                  step="any"
                  name="maxprice"
                  v-model="search.maxPrice"
                  v-bind="moneyMax"
                ></money>
              </div>

              <!-- OPERATION -->
              <!-- <div class="form-control-small form-control-small-responsive">
                <label class="label-switch"
                  >{{ $t("layout.search_form.type_search") }}
                </label>
                <label class="label-switch switchBtn label-switch-responsive">
                  <input v-model="search.searchDevelopments" type="checkbox" />
                  <div class="slide round"></div>
                </label>
              </div> -->

              <!-- LOCATION -->
              <div class="form-control-small">
                <select
                  name="select-city"
                  id="select-city"
                  class="form-control"
                  v-model="search.city"
                >
                  <option selected value="">
                    {{ $t("layout.search_form.location_input") }}</option
                  >
                  <option
                    v-for="(city, i) in !search.searchDevelopments
                      ? input.cities
                      : input.citiesDevs"
                    :key="i"
                    :index="i"
                    :value="city.city"
                  >
                    {{ city.city }}
                  </option>
                </select>
              </div>

              <!-- BUTTON -->
              <button type="submit" class="btn btn-fullcolor">
                {{ $t("layout.search_form.button") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  components: {
    Money,
  },
  data() {
    return {
      search: {
        operation: "",
        type: "",
        typeDevs: "",
        city: "",
        keyword: "",
        minPrice: "",
        maxPrice: "",
        urbanization: "",
        beds: "",
        baths: "",
        parking: "",
        searchDevelopments: false,
      },
      searching: false,
      moneyMin: {
        thousand: ",",
        prefix: "$",
        precision: 0,
        masked: false,
      },
      moneyMax: {
        thousand: ",",
        prefix: "$",
        precision: 0,
        masked: false,
      },
      advanced: false,
    };
  },
  computed: {
    ...mapGetters({
      input: "_getInputs",
    }),
  },
  methods: {
    searchProps() {
      this.searching = true;
      let searchForm = this.search;
      this.$store.dispatch("getResults", searchForm);

      if (!this.search.searchDevelopments) {
        if (this.$route.name != "Results")
          this.$router.push({ name: "Results" });
      } else {
        if (this.$route.name != "ResultsDevs")
          this.$router.push({ name: "ResultsDevs" });
      }

      this.searching = false;
    },
    advancedChange() {
      let temp = this.advanced;
      this.advanced = !temp;
    },
    changeForm() {
      for (let i in this.search) {
        if (i != "searchDevelopments") this.search[i] = "";
      }
    },
  },
  created() {
    this.$store.dispatch("getInputs");
    console.log(this.$route.name);
    if (
      this.$route.name == "Developments" ||
      this.$route.name == "ResultsDevs"
    ) {
      this.search.searchDevelopments = true;
    }
  },
};
</script>

<style scoped>
.switchBtn {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 34px;
}
.switchBtn input {
  display: none;
}
.slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 8px;
  color: #fff;
}
.slide:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slide {
  background-color: #264398;
  padding-left: 40px;
}
input:focus + .slide {
  box-shadow: 0 0 1px #01aeed;
}
input:checked + .slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: 10px;
}

.label-switch {
  margin-right: 30px;
  font-weight: 300 !important;
  margin-bottom: 0 !important;
}

.slide.round {
  border-radius: 34px;
}
.slide.round:before {
  border-radius: 50%;
}

.form-search-detail {
  width: auto;
  overflow: hidden;
}

.form-control {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
  border-color: #e4e4e4;
  color: #74777c;
  height: 41px !important;
  margin-bottom: 10px;
  font-size: 14px;
}

@media (max-width: 980px) {
  .form-control-small-responsive {
    margin-bottom: 25px;
  }

  .label-switch-responsive {
    top: 10px;
  }
}

.form-control-large,
.form-control-small {
  display: inline-block !important;
}
</style>
