<template>
    <div class="main content mb-5">
        <div class="container">
            <div class="row">
                <div id="property-listing" class="grid-style1 clearfix col-md-12" >
                    <div class="row justify-content-center">
                        <PropertyCard  
                        v-for="(item, i) in properties"
                        :key="item.folio"
                        :name="item.folio"
                        :index="i"
                        :item="item"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PropertyCard from "@/components/properties/PropertyCard.vue";

export default {
  components: {
    PropertyCard,
  },
  data() {
    return {
      codeShareProperties: 0,
    };
  },
  computed: {
    ...mapGetters({
      properties: "_getProperties",
    }),
  },
  created() {
    if (this.$route.params.codeShareProperties) {
      this.codeShareProperties = this.$route.params.codeShareProperties;
    }
    this.$store.dispatch("getProperties", this.codeShareProperties);
  },
};
</script>