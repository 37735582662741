<template>
  <!-- BEGIN HIGHLIGHT -->
  <div class="content">
    <div class="container">
      <div class="main">
        <div class="center">
          <h2
            class="section-highlight"
            data-animation-direction="from-left"
            data-animation-delay="50"
          >
            <!-- {{$t("pages.about.company.title")}} -->
            {{ info.companyName }}
          </h2>
          <br />
          <div class="row">
            <div class="col-6">
              <p
                class="darker-text"
                data-animation-direction="from-left"
                data-animation-delay="250"
              >
                {{ $t("pages.about.vision.title") }}
              </p>
              <p
                data-animation-direction="from-left"
                data-animation-delay="650"
              >
                {{ $t("pages.about.vision.description") }}
              </p>
              <br />
              <p
                class="darker-text"
                data-animation-direction="from-left"
                data-animation-delay="250"
              >
                {{ $t("pages.about.mision.title") }}
              </p>
              <p
                data-animation-direction="from-left"
                data-animation-delay="650"
              >
                {{ $t("pages.about.mision.description") }}
              </p>
              <br />

              
            </div>
            <div class="col-6">
                <p
                class="darker-text"
                data-animation-direction="from-left"
                data-animation-delay="250"
              >
                {{ $t("pages.about.objectives.title") }}
              </p>
              <p
                data-animation-direction="from-left"
                data-animation-delay="650"
              >
                {{ $t("pages.about.objectives.description") }}
              </p>
              <br>
              <p
                class="darker-text "
                data-animation-direction="from-left"
                data-animation-delay="250"
              >
                {{ $t("pages.about.values.title") }}
              </p>
              <ul
                data-animation-direction="from-left"
                data-animation-delay="650"
              >
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_one") }}</li>
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_two") }}</li>
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_three") }}</li>
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_four") }}</li>
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_five") }}</li>
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_six") }}</li>
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_seven") }}</li>
                <li><i class="fas fa-check"></i> {{ $t("pages.about.values.example_eight") }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END HIGHLIGHT -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.logo {
  width: 40% !important;
}

.mt-5 {
  margin-top: 40px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  columns: 2;
  text-align: start;
}

p{
        text-align: justify;
}
 
 i{
     padding: 0 10px;
     color: #264398;
 }
</style>
