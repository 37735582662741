<template>
  <main>
    <Preloader v-if="!dev" />
    <div v-else>
      <Banner :image="dev.images[0].largefile" :pageTitle="(activeLocale=='es'|| !dev.developmentNameEn)?dev.developmentNameEs:dev.developmentNameEn"/>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="main-2 col-sm-8">
              <!-- PROPERTY TITLE -->
              <h1 v-if="dev.developmentNameEs" class="property-title mt-title">
                {{ dev.developmentNameEs }}
                <small v-if="dev.address">{{ dev.address }}</small>
              </h1>

              <!-- BEST INFORMATION -->
              <div class="property-topinfo">
                <ul class="amenities">
                  <li v-if="dev.parking!=0">
                    <i class="fas fa-car"></i> {{ dev.parking }}
                  </li>
                  <li v-if="dev.m2cFrom||dev.m2cTo">
                    <i class="icon-area"></i> {{ dev.m2cFrom }} {{(dev.m2cTo)?`- ${dev.m2cTo}`:''}} m <sup>2</sup>
                  </li>
                  <li v-if="dev.bedFrom||dev.bedTo">
                    <i class="icon-bedrooms"></i> {{ dev.bedFrom }} {{(dev.bedTo)?`- ${dev.bedTo}`:''}}
                  </li>
                  <li v-if="dev.bathFrom||dev.bathTo" >
                    <i class="icon-bathrooms"></i> {{ dev.bathFrom }} {{(dev.bathTo)?`- ${dev.bathTo}`:''}}
                  </li>
                </ul>
                <div v-if="dev.folio" id="property-id" class="folio">
                  # {{ dev.folio }}
                </div>
              </div>

              <!-- PRICE  -->
              <div class="row">
                  <div v-if="dev.priceFrom != 0" class="col-md-6">
                      <h2>{{ $t("pages.development_detail.price_from") }}</h2>
                  <strong>
                      {{ dev.priceFrom }}
                  </strong>
            </div>

              </div>
              <br/>

              <!-- CAROUSEL -->
              <DetailCarousel :items="dev.images" />
              <br />

              <!-- DESCRIPTION -->
              <div class="style1 mt-5">
                <h2 class="text-center">
                  {{ $t("pages.property_detail.language_description_en") }}
                </h2>
                <div v-if="dev.descriptionEs != 0">
                  <h4>{{ $t("pages.property_detail.language_description_es") }}</h4>
                  <pre>
                      <p>{{ dev.descriptionEs }}</p>
                  </pre>
                </div>
                <div v-if="dev.descriptionEn != 0">
                  <h4>{{ $t("pages.property_detail.language_description_en") }}</h4>
                  <pre>
                      <p>{{ dev.descriptionEn }}</p>
                  </pre>
                </div>
              </div>

              <!-- AMENITIES -->
              <div v-if="dev.amenities || dev.services || dev.maintenance_includes">
                <h2 class="text-center">
                  {{ $t("pages.property_detail.amenities_prop") }}
                </h2>
                <div class="d-md-flex mt-2 mb-2">
                  <ul class="property-amenities-list">
                    <!-- A. Interiors -->
                    <li class="list-amenities" v-if="dev.amenities">
                      <h4>
                        {{ $t("pages.property_detail.amenities_interior") }}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in dev.amenities"
                          :index="i"
                          :key="i"
                          class="mb-3 mr-3 enabled"
                        >
                          <span><i class="icon-check"></i></span>
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                    <!-- A. Exteriors -->
                    <li class="list-amenities" v-if="dev.maintenance_includes">
                      <h4>
                        {{$t("pages.development_detail.maintenance_includes")}}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in dev.maintenance_includes"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                    <!-- A. Services-->
                    <li class="list-amenities" v-if="dev.services">
                      <h4>
                        {{$t("pages.property_detail.services_prop")}}
                      </h4>
                      <ul class="for-ul property-amenities-list">
                        <li
                          v-for="(item, i) in dev.services"
                          :index="i"
                          :key="i"
                          class="enabled"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <br />

              <!-- LOCATION
              <div class="mb-5" v-if="dev.latitude|| dev.longitude">
                <div class="col-md-12 no-gutters mb-5">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{ $t("pages.property_detail.map_title") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <GoogleMap
                      :mapLat="dev.latitude"
                      :mapLng="dev.longitude"
                    />
                  </div>
                </div>
              </div>
              <br /> -->

              <!-- VIDEO -->
              <div v-if="dev.video">
                <div class="col-md-12 no-gutters mt-3">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{ $t("pages.property_detail.video") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <iframe
                      width="100%"
                      height="315"
                      :src="dev.video"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>


            </div>

            <div class="sidebar gray col-sm-4">
              <h2 class="section-title first">
                {{ $t("pages.property_detail.contact_us") }}
              </h2>
              <div>
                <ContactForm />
              </div>

              <!-- SHARE PROPERTIES -->
              <h2 class="section-title mt-0">
                {{ $t("pages.property_detail.share_prop") }}
              </h2>
              <div class="share-wraper">
                <ul class="social-networks">
                  <li>
                    <a
                      :href="
                        `https://www.facebook.com/sharer/sharer.php?u=${url}`
                      "
                      target="_blank"
                      ><i class="fab fa-facebook" aria-hidden="true"></i></a>
                  </li>
                  <li>
                    <a
                      title="Compartir por Twitter"
                      :href="`https://twitter.com/home?status=${url}`"
                      target="_blank"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      title="Compartir por WhatsApp"
                      :href="
                        `https://api.whatsapp.com/send?text=${$t(
                          'pages.property_detail.share_whats_message_public'
                        )}: ${url}`
                      "
                      target="_blank"
                      ><i class="fab fa-whatsapp"></i></a>
                  </li>
                </ul>
              </div>

               <!-- DOWNLOAD FILES -->
              <div v-if="dev.flyer.flyerEs4 || dev.flyer.flyerEs10 ">
                <h2 class="section-title">
                  {{ $t("pages.property_detail.share_title") }}
                </h2>
                <div class="share-wraper">
                  <ul class="banner row justify-content-center">
                    <p>{{ $t("pages.property_detail.flyers_es") }}</p>
                    <li v-if="dev.flyer.flyerEs4" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEs4"
                      >
                        <i class="fa fa-file-pdf-o"></i> 4 Fotos
                      </a>
                    </li>
                    <li v-if="dev.flyer.flyerEs10" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEs10"
                      >
                        <i class="fa fa-file-pdf-o"></i> 10 Fotos
                      </a>
                    </li>
                    <p>{{ $t("pages.property_detail.flyers_en") }}</p>
                    <li v-if="dev.flyer.flyerEs4" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEn4"
                      >
                        <i class="fa fa-file-pdf-o"></i> 4 Photos
                      </a>
                    </li>
                    <li v-if="dev.flyer.flyerEn10" class="banners col-md-12">
                      <a
                        class="banner-button"
                        target="_blank"
                        :href="dev.flyer.flyerEn10"
                      >
                        <i class="fa fa-file-pdf-o"></i> 10 Phptos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- LOCATION -->
              <div class="mb-5" v-if="dev.latitude|| dev.longitude">
                <div class="col-md-12 no-gutters mb-5 ">
                  <div class="col-12">
                    <h2 class="section-title">
                      {{ $t("pages.property_detail.map_title") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <GoogleMap
                      :mapLat="dev.latitude"
                      :mapLng="dev.longitude"
                    />
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
    SearchForm,
    GoogleMap,
    DetailCarousel,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      dev: "_getDevelopment",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.dev.priceFrom;
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return "$" + formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;

    let data = {
      folio: this.folio,
    };
    this.$store.dispatch("getDevelopment", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
  },
};
</script>


<style scoped>

.mb-5{
  margin-bottom: 20px;
}

strong{
  color: #74777c;
  font-size: 36px;
  font-weight: 200;
}

.not-price {
  color: #616161 !important;
  text-decoration: line-through;
}

.for-ul {
  column-count: 3 !important;
  border-bottom: solid 1px #a7a7a7;
  padding-bottom: 15px;
}

.list-amenities{
  margin-bottom: 20px;
}

.share-wraper {
  border: 1px solid #e3e3e3;
  margin-bottom: 0px !important;
  padding: 0px 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}


.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.banners {
  float: left;
}

.banner-button {
  display: block;
  width: auto;
  border-radius: 5px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  border: 1px solid #919191;
  color: #4d4f52;
 
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.banner-button:hover {
  border: 1px solid #373737;
  color: #264398;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}

.mt-0{
  margin-top: -5px;
}

.folio {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #264398;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif !important;
  color: #264398 !important;
  font-weight: 300 !important;
  margin: 0 !important;
  /*text-rendering: optimizelegibility;*/
}

.mt-title{
  margin-top: 40px !important;
}

.property-title {
    text-transform: none !important;
    font-size: 24pt !important;
    margin-bottom: 40px !important;
    margin-top: 54px !important; 
}

.sidebar.gray .section-title {
    color: #4d4f52 !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}

</style>