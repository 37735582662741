<template>
  <main>
    <!-- Content
    ==================================================-->
    <section>
      <div class="container" v-if="agents.length > 0">
        <div class="row justify-content-center">
            <Preloader v-if="!agents" />
            <AgentCard
                v-else 
                v-for="(item, i) in agents"
                :key="item.folio"
                :name="item.folio"
                :index="i"
                :agent="item"
            />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import AgentCard from "@/components/layout/AgentCard.vue";
export default {
  components: {
    Banner,
    Preloader,
    AgentCard,
  },
  computed:{
        ...mapGetters({
            agents:'_getTeam',
            error:'_getError',
        }),
    },
    created(){
      this.$store.dispatch('getTeam');
    },
};
</script>